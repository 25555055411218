export {getObject, setObjectClass}

function getObject(data, $btn = undefined){
//	console.log('getObject data: ', data, ' $btn: ', $btn);
	var $o = null;
	if(data !== undefined){
		if(data.parent !== undefined){ // zamykające, np. data-hide='{"parent":".container-row","object":"form"}'
			$o = data.object !== undefined ? $btn.closest(data.parent).find(data.object) : $btn.closest(data.parent);
			console.log('getObject ' + data.object + ' by parent ' + data.parent, $o);
		}else{ // eg. data-hide="#formAdmin" - raczej dla jednoznacznych elementów
			$o = data !== 'body' ? $('body').find(data) : $('body');
			console.log('getObject by ID', $o);
		}
	}
//	console.log('getObject object', $o);
	return $o !== undefined && $o && $o.length ? $o : null;
}

function setObjectClass($o, type){
	if($o !== undefined && $o.length){
		if($.isArray($o)){
			$.each($o, function(i, el){
				setClass(el, type);
			});
		}else if ($o instanceof jQuery){
			setClass($o, type);
		}else{
			console.log('$o',$o + ' nie jest obiektem!');
		}
	}
}

function setClass($o, type){
	switch(type){
		case 'hide': if(!$o.hasClass('d-none'))	$o.addClass('d-none');	break;
		case 'show': case'display': if($o.hasClass('d-none')) $o.removeClass('d-none'); break;
		case 'inactive': if($o.hasClass('active')) $o.removeClass('active'); break;
		case 'active': if(!$o.hasClass('active')) $o.addClass('active'); break;
	}
}
