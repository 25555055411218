export {formCheck, validatePesel, validateNip, validateRegon, emailTest, fileValidate, checkUpload, testSize};
import {submitData} from './livedata';
import {beforeAlert, beforeAlertRemove} from './message';
import {readCookie} from './cookie';

function formCheck($form){
	if($form === undefined || !($form instanceof jQuery)){
		console.log("No object $form", $form);
		return false;
	}
	var goodData = true;
	$form.find('input').each(function () {
		var $inp = $(this);
		if(!requireInputTest($inp)) goodData = false;
		// email ma on-line sprawdzaną wartość podczas wypełniania i dostaje klasę error, ale nie wie, że jest pusty, jak był pominięty
		if($inp.is(':visible') && $inp.prop('type') === 'email' && (($inp.prop('required') && !$inp.val().trim().length) || $inp.hasClass('error'))) goodData = false;
	});
	$form.find('textarea').each(function () {
		if(!requireTextareaTest($(this))) goodData = false;
	});
	$form.find('select').each(function () {
		if(!requireSelectTest($(this))) goodData = false;
	});
	return goodData;
}

//////////// SPRAWDZANIE ELEMENTU FORMULARZA ///////////////

// sprawdzenie inputu, w tym checkboxa, czy ma wartość, ale bez emaila
function requireInputTest($inp){
	if ($inp.hasClass('error') && $inp.prop('type') !== 'email') {
		$inp.siblings('.input-group-prepend').removeClass('error');
		$inp.siblings('.input-group-append').removeClass('error');		
		$inp.removeClass('error');
		var $label = $inp.siblings('label');
		if($label !== undefined && $label.hasClass('error')){
			$label.removeClass('error');
		}
		$inp.siblings('.alert').remove();
	}
	if ($inp.hasClass('custom-file-input') && $inp.parent().find('.custom-file-label').hasClass('error')){
		$inp.parent().find('.custom-file-label').removeClass('error');
	}
	var goodData = true;
	if($inp.hasClass('custom-file-input')) {
		if ($inp.hasClass('error-type')) {
			beforeAlert($inp, 'Zły typ pliku, użyj png lub jpg!');
			goodData = false;
		} else if ($inp.hasClass('error-file')) {
			beforeAlert($inp, 'Dłuższy bok nie może przekraczać 2400 pikseli!');
			goodData = false;
		}
	}
	if ( $inp.is(':visible') && $inp.prop('required') && $inp.prop('type') !== 'email' && (
			($inp.prop('type') === 'checkbox' && !$inp.prop("checked"))	||
			($inp.prop('type') !== 'checkbox' && !$inp.val().trim().length)
		)
	) {
		if($inp.prop('type') === 'checkbox'){
			var $label = $inp.siblings('label');
			if($label !== undefined){
				$inp.addClass('error');
				$inp.siblings('label').addClass('error');
			}
		}
		if($inp.hasClass('custom-file-input')){
			var $label = $inp.parent().find('.custom-file-label');
			if($label !== undefined){
				$label.addClass('error');
			}
		}else{
			$inp.siblings('.input-group-prepend').addClass('error');
			$inp.siblings('.input-group-append').addClass('error');
			$inp.addClass('error');
		}
		goodData = false;
	}
	return goodData;
}

// sprawdzenie selecta, czuy ma wartość
function requireSelectTest($sel){
	if ($sel.hasClass('error')) {
		$sel.removeClass('error');
	}
	var goodData = true;
	if ($sel.is(':visible') && $sel.prop('required') && !parseInt($sel.val(), 10)) {
		$sel.addClass('error');
		goodData = false;
	}
	return goodData;
}

// sprawdzenie textarea, czy ma wartość
function requireTextareaTest($txt){
	if ($txt.hasClass('error')) {
		$txt.siblings('.input-group-prepend').removeClass('error');
		$txt.siblings('.input-group-append').removeClass('error');
		$txt.removeClass('error');
	}
	var goodData = true;
	if ($txt.is(':visible') && $txt.prop('required') && !$txt.val().trim().length){
		$txt.siblings('.input-group-prepend').addClass('error');
		$txt.siblings('.input-group-append').addClass('error');
		$txt.addClass('error');
		goodData = false;
	}
	return goodData;
}

function fileValidate($input){
	if ($input.hasClass('error')){
		$input.removeClass('error');
		$input.siblings('.input-group-prepend').removeClass('error');
		$input.siblings('.input-group-append').removeClass('error');
		$input.siblings('.custom-file-label').removeClass('error');
		$input.removeClass('error-file');
		$input.removeClass('error-file-type');
	}
	let regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(.jpg|.png|.gif)$"),
		max = readCookie('maxFileEdge');
	if (regex.test($input.val().toLowerCase())) {
		console.log('$input.val', $input.val());
		console.log('$input[0].files', $input[0].files);
		console.log('$input[0]', $input[0]);
		if ($input[0].files !== undefined && $input[0].files.length) {
			let reader = new FileReader();
			reader.readAsDataURL($input[0].files[0]);
			reader.onload = function (e) {
				let image = new Image();
				image.src = e.target.result;
				image.onload = function () {
					let height = this.height,
						width = this.width;
					console.log('this',this);
					console.log('this.width x $this.height',this.width + 'x' + this.height);
					if (height > max || width > max) {
						$input.addClass('error');
						$input.siblings('.input-group-prepend').addClass('error');
						$input.siblings('.input-group-append').addClass('error');
						$input.siblings('.custom-file-label').addClass('error');
						$input.addClass('error-file');
						beforeAlert($input, this.width + 'px x ' + this.height + 'px - zmniejsz obrazek! Maksymalna długość boku nie może być większa, niż ' + max + 'px.');
						return false;
					}else{
						beforeAlertRemove($input);
					}
					return true;
				};
			};
		} else {
			alert("This browser does not support HTML5.");
			return false;
		}
	} else {
		let regex2 = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(.svg)$");
		if (!regex2.test($input.value.toLowerCase())) {
			$input.addClass('error');
			$input.siblings('.input-group-prepend').addClass('error');
			$input.siblings('.input-group-append').addClass('error');
			$input.addClass('error-file-type');
			return false;
		}
	}
}

function checkUpload($form){
	var $upload = $form.find('input[name="upload"]'),
		$upload2 = $form.find('input[name="uploadTop"]'),
		maxSize = readCookie('maxFileSize');

	if($upload === undefined){
		console.log('Input do uploadu głównego pliku, musi mieć nazwę <b>upload</b>!');
		return false;
	}
	console.log('function checkUpload maxSize ' + maxSize, $upload);
	let result = testSize($upload, maxSize);
	if(result && $upload2 !== undefined){
		result = testSize($upload2, maxSize);

	}

	return result;
}

function testSize($upload, max){
	if($upload.length && $upload[0].files.length){
		let	uploadSize = $upload[0].files[0].size;

		if(uploadSize > max - 10){
			beforeAlert($upload, 'Plik jest za duży o ' + formatBytes(uploadSize - (max + 10)) + ', ma ' + formatBytes(uploadSize) + ',<br>może mieć max: ' + formatBytes(max - 10) + ' (' + (max + 10) + ' B).');
			return false;
		}
	}
	return true;
}



function validatePesel(pesel) {
	var reg = /^[0-9]{11}$/;
	if (reg.test(pesel) === false) {
		return false;
	} else {
		var digits = ("" + pesel).split("");
		if ((parseInt(pesel.substring(4, 6)) > 31) || (parseInt(pesel.substring(2, 4)) > 12)) {
			return false;
		}
		var checksum = (1 * parseInt(digits[0]) + 3 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 9 * parseInt(digits[3]) + 1 * parseInt(digits[4]) + 3 * parseInt(digits[5]) + 7 * parseInt(digits[6]) + 9 * parseInt(digits[7]) + 1 * parseInt(digits[8]) + 3 * parseInt(digits[9])) % 10;
		if (checksum === 0) {
			checksum = 10;
		}
		checksum = 10 - checksum;
		return (parseInt(digits[10]) === checksum);
	}
}

function validateNip(nip) {
	var nipWithoutDashes = nip.replace(/-/g, "");
	var reg = /^[0-9]{10}$/;
	if (reg.test(nipWithoutDashes) === false) {
		return false;
	} else {
		var digits = ("" + nipWithoutDashes).split(""),
			checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;
		return (parseInt(digits[9]) === checksum);
	}
}

function validateRegon(regon) {
	var reg = /^[0-9]{9}$/;
	if (!reg.test(regon)) {
		return false;
	} else {
		var digits = ("" + regon).split(""),
			checksum = (8 * parseInt(digits[0]) + 9 * parseInt(digits[1]) + 2 * parseInt(digits[2]) + 3 * parseInt(digits[3]) + 4 * parseInt(digits[4]) + 5 * parseInt(digits[5]) + 6 * parseInt(digits[6]) + 7 * parseInt(digits[7])) % 11;
		if (checksum === 10) {
			checksum = 0;
		}
		return (parseInt(digits[8]) === checksum);
	}
}

// jako $inp - obiekt inputa z typem email
function emailTest($inp) {
	console.log($inp);
	if(!$inp.length || $inp.prop('type') !== 'email') return false;
	if ($inp.hasClass('error')){
		$inp.removeClass('error');
		$inp.siblings('.input-group-prepend').removeClass('error');
		$inp.siblings('.input-group-append').removeClass('error');
		return false;
	}
	var email = $inp.val().toLowerCase().trim();
	if ($inp.prop('required') && !email.length) {
		$inp.addClass('error');
		$inp.siblings('.input-group-prepend').addClass('error');
		$inp.siblings('.input-group-append').addClass('error');
		return false;
	}
	$inp.val(email); // jakby czasem były duze litery lub spacje
	var action = 'https://' + readCookie('siteDomain') + '/test/email/' + email;
	$.get(action, function (response) {
		// return
		try {
			// response is json
			var o = jQuery.parseJSON(response);
			console.log('RESPONSE JSON', o);
		} catch (error) {
			var o = response;
			console.log('RESPONSE NoJSON', o);
		}
		if(o === 0){
			console.log('Test e-maila ' + email + ' - zły!');
			$inp.addClass('error');
			$inp.siblings('.input-group-prepend').addClass('error');
			$inp.siblings('.input-group-append').addClass('error');
		}else if (o === 1){
			console.log('Test e-maila ' + email + ' - OK!');
			$inp.removeClass('error');
			$inp.siblings('.input-group-prepend').removeClass('error');
			$inp.siblings('.input-group-append').removeClass('error');
		}else{
			console.log('Test e-maila ' + email + ' - brak odpowiedzi!');
		}
	});
}
