export {runEditor, closeEditor}
import {getObject} from './object';

function runEditor(classIdOrObject = '.editor', height = 200, $btn = undefined ){
	var $obj = searchObject(classIdOrObject, $btn);
	if($obj){
		$obj.summernote({
			height: height,
			dialogsInBody: true,
			toolbar: [
						// [groupName, [list of button]]
						['style', ['bold', 'italic', 'underline', 'clear']],
						['font', ['strikethrough', 'superscript', 'subscript']],
						['size', ['fontsize']],
						['line', ['height']],
						['color', ['forecolor']],
						['paragraph', ['ul', 'ol', 'paragraph']],
						['insert', ['link', 'table', 'hr']],
						['misc', ['undo', 'redo', 'fullscreen', 'codeview']]
					]
		});
	}else{
		console.log("Textarea not found");
	}
}

function closeEditor(classIdOrObject = '.editor', $btn = undefined ){
	var $obj = searchObject(classIdOrObject, $btn);
	if($obj){
		$obj.summernote('destroy');
	}else{
		console.log("Textarea not found", classIdOrObject);
	}
}

function searchObject(classIdOrObject = '.editor', $btn){
	var $obj = undefined;
	if (classIdOrObject.parent !== undefined && $btn !== undefined) {
		$obj = getObject(classIdOrObject, $btn);
	} else if (classIdOrObject instanceof jQuery) {
		$obj = classIdOrObject;
	} else {
		$obj = $('body').find(classIdOrObject);
	}
	return $obj !== undefined && $obj.length ? $obj : false;
}