export {afterSend};
import {createCookie, checkCookie, readCookie, searchToCookieName} from './lib/cookie';
import {getUrlParam, getServerUrl, getSiteUrl, getPathnameElements} from './lib/url';
import {dataFromForm, getData, submitData, submitDataFile, liveLoadData} from './lib/livedata';
import {strToArray, scroll_to, formatBytes} from './lib/helpers';
import ClipboardJS from './lib/clipboard';
import {beforeAlert, beforeAlertRemove} from './lib/message';
import {formCheck, validatePesel, validateNip, validateRegon, emailTest, fileValidate, checkUpload, testSize} from './lib/test';
import {getObject, setObjectClass} from './lib/object';
import {plusSlides, currentSlide, showSlides} from './lib/lightbox';
import {runEditor} from './lib/editor';

$(document).ready(function () {

	var $body = $('body'),
		$header = $('body > header'),
		$linkLogo = $header.find('.navbar-brand'),
		$main = $body.find('main'),
		isHome = getServerUrl() === getSiteUrl();

	/**
	 * Admin Menu - Każda strona ma możliwość wprowadzenia panelu administracyjnego
	 */

	let modalAddMedia = $('#modalAddMedia');
	let menu = document.querySelector('.modal-menu');
	let form = document.querySelector('.modal-form'),
		contentForm = $(form).find('.modalContentAddMedia'),
		mediaTypes = ['analytics', 'tags', 'calendly', 'chat', 'benefits', 'course', 'event', 'gallery', 'news', 'offer'];

	modalAddMedia.on("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function(event){
		switch (event.originalEvent['animationName']) {
			case 'backOutUp':
				$(form).removeClass('d-none');
				$(form).addClass('backInUp');

				$(menu).addClass('d-none');
				$(menu).removeClass('backOutUp');
				break;
			case 'backOutDown':
				$(form).addClass('d-none');
				$(form).removeClass('backOutDown');

				$(menu).removeClass('d-none');
				$(menu).addClass('backInDown');

				contentForm.empty();
				resetModalTitles(form, mediaTypes);
				break;
			case 'backInDown':
				$(menu).removeClass('backInDown');
				break;
		}
	});

	//Kliknięcie w dodanie miasta do listy w body
	$body.on('click', '.item-city .item-city-add', function() {
		let item = $(this).closest('.item-city'),
			itemInput = $(item).find('.city-value'),
			itemValue = $(this).val(),
			delIcon = $(item).find('.item-city-remove'),
			parent = $(item).closest('.item-append-list'),
			addedList = $(parent).find('.item-selected'),
			rowAdd = $(addedList).find('.row');

		$(this).addClass('d-none');
		$(delIcon).removeClass('d-none');
		$(itemInput).attr('name', 'cities[' + itemValue + ']');

		$(item).appendTo(rowAdd);
	});

	//Kliknięcie w usunięcie miasta z listy w body
	$body.on('click', '.item-city .item-city-remove', function(){
		let item = $(this).closest('.item-city'),
			itemInput = $(item).find('.city-value'),
			addIcon = $(item).find('.item-city-add'),
			parent = $(item).closest('.item-append-list'),
			availableList = $(parent).find('.item-selector'),
			rowCities = $(availableList).find('.item-city-row');

		$(this).addClass('d-none');
		$(addIcon).removeClass('d-none');
		$(itemInput).removeAttr('name');
		$(item).appendTo(rowCities);
	});

	modalAddMedia.on("hidden.bs.modal", function () {
		resetModalTitles(form, mediaTypes);
		$(form).addClass('d-none');
		$(menu).removeClass('d-none');
	});

	modalAddMedia.on('click', '.back', function(){
		$(form).addClass('backOutDown');
	});

	modalAddMedia.on('click', '.modal-body .select-type', function(){
		let $select = $(this),
			data = $select.data(),
			fill = data['fill'],
			value = data['value'];

		if($.inArray(value, mediaTypes) <= -1){
			return false;
		}
		$select.closest('.modal-dialog').addClass('backOutUp');
		$(form).find('.modal-title-' + value).removeClass('d-none');
		getData(data.action, $(fill));

		$(form).find('.back').show();

		if(data.after !== undefined){
			setTimeout(function(){
				let bodyForm = $(form).find('.modalContentAddMedia');
				afterSend({'after':data.after}, bodyForm);
			}, 1000);
		}
	});

	//Administracyjny pasek nawigacji
	let $adminPanel = $('main').find('.admin-panel'),
		$menu = $adminPanel.find('.menu'),
		$queryMenu = $($menu),
		$mainMenu = $queryMenu.find('.main-menu'),
		$mainMenuAbsolute = $adminPanel.find('.main-menu-absolute'),
		$menuAddButton = $($mainMenu).find('.buttonExpander'),
		$submenu = $queryMenu.find('.submenu');

	$(window).on('scroll', function (event) {
		// if (document.body.scrollHeight ==
		// 	document.body.scrollTop +
		// 	window.innerHeight) {
		// 	alert("Bottom!");
		// }
		let classOutAnimation = 'back-out-up'
		let classInAnimation = 'back-in-down'

		let classOutAnimationAbsolute = 'back-out-down'
		let classInAnimationAbsolute = 'back-in-up'

		if($(document).scrollTop() >= 5) {
			if(!$mainMenu.hasClass(classOutAnimation)) {
				$mainMenu.removeClass(classInAnimation);
				$mainMenu.addClass(classOutAnimation);

				//Usunięcie niewidoku (tylko po raz pierwszy)
				$mainMenuAbsolute.removeClass('d-none');

				$mainMenuAbsolute.removeClass(classOutAnimationAbsolute);
				$mainMenuAbsolute.addClass(classInAnimationAbsolute);
			}
		} else {
			if($mainMenu.hasClass(classOutAnimation)) {
				$mainMenu.removeClass(classOutAnimation);
				$mainMenu.addClass(classInAnimation);
				$mainMenuAbsolute.removeClass(classInAnimationAbsolute);
				$mainMenuAbsolute.addClass(classOutAnimationAbsolute);
			}
		}
	});

	//mini-toolbar Symfony
	var $sf = $('body').find('.sf-toolbar');
	if(checkCookie('dev') && parseInt(readCookie('dev')) === 1){
		if($sf.hasClass('sf-display-none')){
			$sf.removeClass('sf-display-none');
			$sf.find('.sf-minitoolbar').css('display','block');
		}
	}else{
		if(!$sf.hasClass('sf-display-none')){
			$sf.find('.sf-minitoolbar').css('display','none');
			$sf.addClass('sf-display-none');
		}
	}

	let copyButton = $('#click-button');
	let clipboard = new ClipboardJS('#click-button');
	let tooltipActive = false;

	clipboard.on('success', function(e) {
		let copyButtonMessage  = "Skopiowano do schowka!";
		e.clearSelection();
		copyButton.focus();
		if (!tooltipActive) {
			copyMessageTooltip(copyButton, copyButtonMessage);
		}
	});

	function copyMessageTooltip(copyButton, copyButtonMessage) {
		tooltipActive = true;

		let toolTip = $('#copy_tooltip');
		let tooltipVisibleTime = 2000;
		let tooltipHideTime = 100;

		toolTip.text(copyButtonMessage).addClass('active');
		copyButton.attr('aria-describedby','copy_tooltip');

		setTimeout(function() {
			toolTip.removeClass('active').addClass('inactive');
			toolTip.replaceWith(toolTip.clone(true));
			copyButton.removeAttr('aria-describedby');
			setTimeout(function() {
				toolTip.removeClass('inactive').text('');
				tooltipActive = false;
			}, tooltipHideTime);
		}, tooltipVisibleTime);
	}

	// przypisanie data-pageWidth do body dla skryptów tworzących dynamiczne dane
	createWidthCookie();

	var resizeTimer = false;
	$(window).on('resize', function(e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			resizeTimer = false;
			$(window).trigger('resizeEnd');
		}, 200);

	}).on('resizeEnd', function(){
		createWidthCookie();
	});

	// Uniwersalne scrollowanie do targetu zawartego w data-toggle="{'scroll': 'TARGET'}"
	$body.on('click', '.scrollable', function() {
		let toggleData = $(this).data('toggle');
		if($.isArray(toggleData)) {
			for (const tdItem of toggleData) {
				if (typeof tdItem === 'object') {
					if (tdItem.hasOwnProperty('scroll')) {
						scroll_to(tdItem['scroll']);
					}
				}
			}
		} else {
			if (toggleData.hasOwnProperty('scroll')) {
				scroll_to(toggleData['scroll']);
			}
		}
	});

	// kopiowanie pojedyncze
	$body.on('click', '.data-copy', function(){
		// {"parent":"div", "object":"textarea", "where":"append", "what":"text"}
		var $this = $(this),
			$data = $this.data('copy'),
			$to = $data !== undefined && $data.parent !== undefined && $data.object !== undefined ? getObject($data, $this) : null,
			where = $data.where !== undefined ? $data.where : 'append', // lub 'prepend'
			what = $data.what !== undefined ? $data.what : 'text', // lub 'html'
			content = null; 
		switch(what){
			case 'text' : content = $this.text(); break;
			case 'html' : content = $this.html(); break;
		}
		console.log('where: ' + where + ', what: ' + what + ', content: ' + content + ', $to: ', $to);
		if($to && content){
			switch (where) {
				case 'prepend' : $to.prepend("\n"); content = content + ' '; $to.prepend(content); $to.focus(); break;
				case 'append'  : $to.append("\n"); $to.append(content); $to.append(' '); $to.focus(); break;
			}
		}
	});
	
	// kopiowanie w pętli do elementów z wybranergo obiektu
	$body.on('click', '.data-copy-data', function(){
		// [{ "value": "{{ m.id }}", "fill": {"parent":".card-text", "object": "input[name=id]"}, "typeFill": "val" },...]
		var $this = $(this),
			$data = $this.data('copy'),
			clear= $this.data('clear');
		$this.parent().find('.active').removeClass('active');
		$this.addClass('active');
		console.log('data', $data);
		if(!$.isArray($data)) return false;
		for (let i = 0; i < $data.length; ++i) {
			pasteData($data[i], $this);
		}
		if(clear !== undefined){
			var $clear = getObject(clear, $this);
			if($clear !== undefined){
				$clear.val('');
			}
		}
	});

	//Uniwersalne czytanie phrase-selected
	$body.on('keyup', '.phrase-selected', function(event){
		if ( event.which !== 13 ) {
			var $fill = $($(this).data('fill')),
				$searchKey = $(this).val();

			let $data = {'phrase': $searchKey };
			submitData($data, $(this).data('action'), $fill);

			if( $searchKey.length > 0 && $fill.hasClass('d-none')){
				$fill.removeClass('d-none');
			}else if($searchKey.length < 1 && !$fill.hasClass('d-none')){
				$fill.addClass('d-none');
			}
		}
	});

	//Uniwersalne czytanie phrase-selected
	$body.on('select', '.phrase-selected', function(){
		var $fill = $($(this).data('fill')),
			$searchKey = $(this).val();

		let $data = {'phrase': $searchKey };
		submitData($data, $(this).data('action'), $fill);

		if( $searchKey.length > 0 && $fill.hasClass('d-none')){
			$fill.removeClass('d-none');
		}else if($searchKey.length < 1 && !$fill.hasClass('d-none')){
			$fill.addClass('d-none');
		}
	});


	$body.on('click', '.stop-propagation', function (event) {
		event.stopPropagation();
	});

	$('#calendlyWidget').on('shown.bs.collapse', function () {
		scroll_to(this);
	});

	$body.on('click', '.clear-element', function () {
		removeElement($(this));
	});

	// Wybranie pozycji spowoduje usunięcie zawartości formularza wraz z zaznaczneiem bierzącego elementu.
	$body.on('click', '.form-select-reset', function () {
		let form = $(this).closest('form');
		form.get(0).reset();
		$(this).prop("checked", true);
	});

	//Przy sprawdzaniu formularza określamy czy alternatywne pole zostało wprowadzone.
	$body.on('click', '.submit-form', function () {
		let form = $(this).closest('form'),
			alternativeInput = form.find('.alternative-input'),
			alternative = alternativeInput.data('alternative'),
			finderList = form.find('.finder-list'),
			isChecked = finderList.find("input[type='radio']:checked").val();

		if(isChecked === undefined) {
			$(alternative).prop('required', alternativeInput.val() === "");
		}
	});

	$body.on('click', '.finder-list .radio', function () {
		let searchBar = $(this).closest('.finder-block').find('.phrase-add');
		$(searchBar).removeAttr('required');
	});

	// Po kliknięciu w przycisk edycji
	$body.on('click', '.edit-button', function() {
		$(form).removeClass('d-none');
		$(form).find('.back').hide();
		$(menu).addClass('d-none');
	});

	// po otwarciu modala
	$body.on('shown.bs.modal', '.modal', function(){
		// aktywacja tooltipów
		$(this).find('[data-toggle="tooltip"]').tooltip();
		// aktywujemy edytor
		var $editor = $(this).find('.editor');
		if($editor !== undefined){
			runEditor($editor);
		}
		// coś ukrywa header w modalu po zamknięciu, ale co?????
		var $header = $(this).find('.modal-header');
		if($header.css('display') === 'none'){
			$header.css({'display':''});
		}
	});

	// nietypowe zamykanie modala ze względu na wewnętrznego modala edytora
	$body.on('click', '.close-modal', function(){
		$(this).closest('.modal').modal('hide');
	});

	// przed zamknięciem modala, zamyka wszelkie karty accordion
	$body.on('hidden.bs.modal', '.modal', function () {
		if(!$(this).hasClass('note-modal')){
			// jeśli okno modalne nie nalezy do modala edytora
			var $collapse = $(this).find('.collapse');
			if($collapse !== undefined ){
				$collapse.collapse('hide');
			}
		}
	});
	// zamyka film po zamknieciu modala zwykłego
	$body.on('hidden.bs.modal', '.modal', function () {
		resetIframe($(this).find('iframe'));
		resetVideo($(this).find('video'));
	});
	// zamyka film po zamknięciu modala z karuzelą
	$body.on('click', '.modal > .close', function () {
		resetIframe($(this).parent().find('iframe'));
		resetVideo($(this).parent().find('video'));
	});
	// zamyka film po przejściu na kolejny/wcześniejszy slajd
	$body.on('click', '.modal .modal-content > a', function () {
		if($(this).hasClass('prev') || $(this).hasClass('next')){
			resetIframe($(this).parent().find('iframe'));
			resetVideo($(this).parent().find('video'));
		}
	});
	// zamyka film po zmianie slajdu przez thumb w modalu
	$body.on('click', '.modal .slide-thumb', function () {
		resetIframe($(this).closest('.modal').find('iframe'));
		resetVideo($(this).closest('.modal').find('video'));
	});

	// przejście z logo na home tylko nie dla home
	$linkLogo.on('click', function(e){
		$(this).blur();
		e.preventDefault();
		if(!isHome){
			window.location.assign($(this).prop('href'));
		}
	});

	// info o cookies
	var $cookieBar = $body.find('#cookie-info');
	if(!checkCookie('cookies_notify')){
		$cookieBar.show();
	}
	$cookieBar.on('click', '.cookie-btn', function(){
		$cookieBar.fadeOut(500);
		createCookie('cookies_notify', true, 30);
	});
	
	// testowanie wielkości pliku w pikselach przy uploadzie
	$body.on('change', '.file-validator', function() {
		fileValidate($(this));
	});
	
	// toggle collapse with caret rotate
	$body.on('click', '.btn-caret', function(){
		var icon = $(this).find('.fas');
		if(icon.hasClass('fa-caret-right')){
			icon.removeClass('fa-caret-right'); 
			icon.addClass('fa-caret-down');
		}else{ 
			icon.removeClass('fa-caret-down'); 
			icon.addClass('fa-caret-right');
		}
		$(this).blur();
	});

	// przypisywanie klasy active do pozycji nawigacji
	$('.nav-tabs .nav-link').on('click', function(){
		$(this).parents('.nav-tabs').find('.nav-item.active').removeClass('active');
		$(this).parent().addClass('active');
		$(this).blur();
	});

	// Nazwa załadowanego pliku w przypadku pola custom (upload)
	$('body').on("change", 'input[type="file"]', function() {
		var fileName = $(this).val().split("\\").pop(),
			$label = $(this).siblings('label');
//		console.log('filename', fileName);
		$label.addClass("selected").html(fileName);
	});

	// paginacja z formularzem search - musi byc klasa parent .paginatin-list
	// podmiana zawartości i akcja do wyboru:
	// 1. $('.search-form').data('fill') + $('.search-form').prop('action")
	// 2. $('.pagination-list').data('fill') + $('.pagination-list').data('action')
	$body.on('click', '.pagination-list .page-link', function(e){
		e.preventDefault();
//		var $searchForm = $main.find('.search-form');
		var $fill = $(this).closest('.pagination-list'),
			searchForm = $fill.data('form'),
			action = $fill.data('action');
		if(searchForm === undefined){
			console.log('.pagination-list', 'Dodaj data-form!');
			return false;
		}
		if(action === undefined){
			console.log('.pagination-list', 'Dodaj data-action!');
			return false;
		}
		var $searchForm = getObject(searchForm, $fill),
		    data = dataFromForm($searchForm);
		submitData(data, action  + '/' + $(this).data('page'), $fill);
		// bo strony mogą byc galerią
		setTimeout(function(){activationGalleries();}, 1000);
	});

	// paginacja z wyszukiwarką na piny - musi byc klasa parent .finder-list
	$body.on('click', '.finder-list .page-link', function(e){
		e.preventDefault();
		var $fill = $(this).closest('.finder-list'),
			action = $fill.data('action') + '/' + $(this).data('page'),
			$searchForm = getObject($fill.data('form'), $fill);
		console.log('$fill: ', $fill, ' action: ', action, ' $searchForm', $searchForm);
		if($searchForm !== undefined && $fill !== undefined && action !== undefined){
			var	data = dataFromForm($searchForm, false);
			// odbierać w kontrolerze dane: $query = $request->request->all();
				submitData(data, action, $fill);
		}else{
			console.log('.finder-list .page-link', 'Brak formularza wyszukiwania .finder-form luv $fill lub action...');
		}
	});

    // paginacja bez search - musi być klasa parent .all-list
	// podmiana zawartości div.all-list
	$body.on('click', '.all-list .page-link', function(e){
		e.preventDefault();
		var $fill = $(this).closest('.all-list'),
            action = $fill.data('action');
		if(action === undefined){
			console.log('.all-list', 'Brak data-action!');
			return false;
		}
		getData(action  + '/' + $(this).data('page'), $fill);
		// bo strony mogą byc galerią
//		setTimeout(function(){activationGalleries();}, 1000);
	});

		// akcje admina
	$body.on('click', '.admin-action.btn', function () {
		buttonAction($(this));
		$(this).blur();
	});

	// inne akcje
	$body.on('click', '.action-button', function () {
		buttonAction($(this));
		$(this).blur();
	});

	// akcja admina zmiany kolejności na liście
	// wykonanie po 2 sekundach od zaprzestania zmiany wartości
	$body.on('change','.form-order > input[type="number"]', function(){
		var $btn = $(this), valueStep = $btn.val();
		setTimeout(function(){
			var valueFinally = $btn.val();
			if(valueStep === valueFinally){
				buttonAction($btn);
			}
        },1000);
	});

	$body.on('click', '.clear-fields', function(){
		$(this).closest('.input-group').find('input').val('');
		$(this).blur();
	});

	$body.on('click', '.clear-msg', function(){
		$(this).parent().remove();
		$(this).blur();
	});

	////////// ORDER //////////
	// .order-block .btn wysyła
	// .order-block input ma data z fill i action
	// fill ma .pagination .page-item.active .page-link z data-page (nr strony) a może i data-limit
//	$body.on('click', '.order-block .btn', function(){
//		var $block = $(this).closest('.order-block'),
//			data = $block.find('input').data();
//	});


	// uniwersalne przyciski ukrywania i pokazywania z własną "aktywacją"
	// (toggle)
	$body.on('click', '.display-button', function(){
		console.log('click');
		var $btn = $(this),
			$o = getObject($btn.data('display'), $btn);
		console.log('toggle-display', $o);
		if($o.hasClass('d-none')){
			setObjectClass($o, 'show');
			setObjectClass($btn, 'active');
		}else{
			setObjectClass($o, 'hide');
			setObjectClass($btn, 'inactive');
		}
		$btn.blur();
	});

	// ukrywanie obszaru przyciskiem cancel, który jest WEWNĄTRZ ukrywanego obszaru
	// + zdjęcie klasy active z przycisku aktywacji obszaru
	$body.on('click', '.hide-button', function(){
		var $cancelBtn = $(this),
			h = $cancelBtn.data('hide'),
			i = $cancelBtn.data('inactive');
		if(h !== undefined){
			if($.isArray(h)){
				$.each(h, function(i, el){
					setObjectClass(getObject(el, $cancelBtn), 'hide');
				});
			}else{
				setObjectClass(getObject(h, $cancelBtn), 'hide');
				// klasa inactive dla buttona, jeśli nie ma data-inactive
				if(i === undefined){
					var $btn = getObject(h + '-button', $cancelBtn);
					if($btn !== undefined) setObjectClass($btn, 'inactive');
				}
			}
		}
		// klasa inactive dla buttona, jeśli jest osobne data-inactive
		if(i !== undefined){
			setObjectClass(getObject(i, $cancelBtn), 'inactive');
		}
	});

	// wyświetlanie/chowanie elementów wskazanych przez radio, np kilka:
	// data-hide='[{"parent":"form","object":".upload"},{"parent":"form","object":".upload2"}]'
	// albo jeden: data-display='{"parent":"form","object":".upload"}'
	// bądź kilka wprost określone: data-display='["#element1", "#element2"]'
	// lub jeden określony wprost: data-hide="#addEventMediaUpload"
	$body.on('change','.set-display input[type="radio"]', function(){
		var $btn = $(this),
		toHide = $btn.data('hide'),
		toShow = $btn.data('display') !== undefined ? $btn.data('display') : $btn.data('show');

		setDisplay($(this), toHide, toShow);
	});

	// wyświetlanie/chowanie elementu wskazanego przez value select:
	// <option value="hide:#idObiektuDoUkrycia">Coś tam</qption>
	// <option value="show:#idObiektuDoPokazania">Coś tam innego</qption>
	$body.on('change','select.set-display', function(){
		var $select = $(this),
		    value = strToArray(':',$select.val()),
			toHide = value[0] === 'hide' ? value[1] : undefined,
			toShow = value[0] === 'show' || value === 'display' ? value[1] : undefined;
		console.log('select .set-display value, toHide, toShow | ', value, toHide, toShow);
		setDisplay($(this), toHide, toShow);
	});

	// wyświetlanie/chowanie elementu wskazanego przez checkbox dla tłumaczeń
	$body.on('change','.display-lang input[type="checkbox"]', function(){
		var $btn = $(this),
			state = $btn.prop('checked');
			console.log('lang', state, $btn.data('display'));
		switch(state){
			case true:
				setObjectClass(getObject($btn.data('display'), $btn), 'show');
				$(getObject($btn.data('display'), $btn)).find('.usability-control').prop('disabled', false);
				break;
			case false:
				setObjectClass(getObject($btn.data('display'), $btn), 'hide');
				$(getObject($btn.data('display'), $btn)).find('.usability-control').prop('disabled', true);
				break;
			default:
				console.log('Język', 'Niezdefiniowany stan checkboxa');
		}
	});

	// zmiana języka
	$header.on('click', '.set-lang', function(){
		createCookie('_locale', $(this).data('lang'), 360);
		createCookie('country_id', $(this).data('country'), 360);
		createCookie('user_locale', $(this).data('lang'), 360);
		window.location.reload(true);
	});

	// można sobie podmieniać tekst buttona z klasą wait
	$body.on('click','.wait',function(){
		$(this).text('czekaj...');
		return true;
	});

	// test istnienia wartości pola
	$body.on('change', '.check-is-value', function(){
		var $field = $(this),
			value = $field.val(),
			action = $field.data('action') + '/' + value;
		if(value.length > 1){
			getData(action, $field, 'error', null, $field);
		}else{
			$field.addClass('error');
		}
	});

	// dimiss prywatne - chowa obiekt nadrzędny przycisku .close,
	$body.on('click', '.close', function(){
		var $parent = $(this).parent();
		if($parent.hasClass('alert') || $parent.hasClass('message')){
			// komunikat niszczymy
			$parent.fadeOut(400).remove();
		}else{
			// tylko zamykamy to coś
			$parent.fadeOut(400);
		}
	});

	/////// Edycja jednego pola input ///////

	// pokazanie pola edycji z buttonem, schowanie wyświetlanych danych
	$body.on('click', '.edit-field', function(){
		var $btn = $(this),
			$edited = $btn.siblings('.edited'),
			$displayed = $btn.siblings('.displayed');
		$displayed.fadeOut(400);
		$btn.fadeOut(400);
		$edited.removeClass('d-none');
	});
	// zapis pola, podmiana danych, wyświetlenie komunikatu
	$body.on('click', '.input-group.edited .fa.fa-check', function(){
		var $btn = $(this),
			action = $btn.data('action'),
			$input = $btn.closest('.input-group-append').siblings('input'),
			value = $input.val(),
			$inputGroup = $btn.closest('.input-group'),
			$displayed = $inputGroup.siblings('.displayed'),
			$btnEdit = $inputGroup.siblings('.edit-field');
		console.log('action', action, value);
		submitData(value, action, $displayed, 'text', null, null, $displayed);
		$btn.closest('.input-group').addClass('d-none');
		$displayed.fadeIn(400);
		$btnEdit.fadeIn(400);
	});
	// anulowanie zapisu pola
	$body.on('click', '.input-group.edited .fa.fa-times', function(){
		var $btn = $(this),
			$inputGroup = $btn.closest('.input-group'),
			$displayed = $inputGroup.siblings();
		$inputGroup.addClass('d-none');
		$displayed.fadeIn(400);
	});
	/////////////////////////////////////////

	// akcje typu get buttonów admina w tabeli
	$body.on('click', 'table tr td.admin-col button', function(){
		var $btn = $(this),
			action = $btn.data('action'),
			after = $btn.data('after'),
			question = $btn.data('confirm'),
			sendOK = question === undefined ? true : false;
		if(question !== undefined){
			sendOK = confirm(question);
		}
		if(action.length && sendOK){
			getData(action, null, 'text', null, null, '#alerts');
			switch(after){
				case 'remove':
					$btn.closest('tr').remove();
					break;
			}
		}
	});

	/////////////////////// WYSZUKIWARKA ///////////////////////
    // wyszukiwarka jest wewnątrz własnego bloku .finder-data //
	// blok .finder-data musi mieć: data-fill                 //
	// obiekt fill musi mieć:                                 //
	// 1. klasę finder-list                                   //
	// 2. data-action                                         //
	// 3. data-form                                           //
	// Zalecane: Całość w bloku o klasie finder-block         //
	///////////////(include phrase-add.html.twig)///////////////
    // dodanie tagu wyszukiwania
    $body.on('keypress', '.phrase-add', function (e) {
        if (e.which === 13 || e.which === 188)
            return false; // żeby Enter nie wysłał formularza
    });
    $body.on('keydown', '.phrase-add', function (e) {
        if (e.which === 13 || e.which === 188)
            return false; // żeby Enter nie wysłał formularza
    });
	$body.on('change', '.phrase-filter select', function (e) {
		var $addButton = $(this),
			$add = $addButton.closest('.finder-group').find('.phrase-add'),
			$form = $add.closest('form'),
			fill = $add.closest('.finder-data').data('fill'),
			$fill = getObject(fill, $add),
			action = $fill.data('action') + '/1';

		var data = dataFromForm($form, false);
		submitData(data, action, $fill);
	});
	$body.on('change', '.phrase-filter', function (e) {
		var $addButton = $(this),
			$add = $addButton.closest('.finder-group').find('.phrase-add'),
			$form = $add.closest('form'),
			fill = $add.closest('.finder-data').data('fill'),
			$fill = getObject(fill, $add),
			action = $fill.data('action') + '/1';

			var data = dataFromForm($form, false);
			submitData(data, action, $fill);
			//Nie dziala?
	});
	$body.on('click', '.phrase-button-add', function (e) {
		var $addButton = $(this),
			$add = $addButton.closest('.finder-group').find('.phrase-add'),
			$form = $add.closest('form'),
			fill = $add.closest('.finder-data').data('fill'),
			$fill = getObject(fill, $add),
			action = $fill.data('action') + '/1',
			$tags = $add.closest('.finder-fields').find('.tags-area'),
			$messages = $add.closest('.finder-fields').find('.msg-area'),
			nr = $tags.find('input').length + 1,
			search = $.trim($add.val().replace(',',''));

		if (search.length > 2) {
			$tags.append('<div class="tag">' +
				'<div class="card">' +
				'<div class="card-body">' +
				'<input readonly type="text" class="form-control-plaintext" name="phrase[' + nr + ']" value="' + search + '">' +
				'<i class="fas fa-times clear-tag"></i>' +
				'</div>' +
				'</div>');
			$add.val('');
			$messages.html('');
			var data = dataFromForm($form, false);
			submitData(data, action, $fill);
			return false; // żeby Enter nie wysłał formularza
		} else {
			$messages.html('<span class="msg mb-3">Wpisz minimum 3 znaki!</span><i class="fas fa-times clear-msg"></i>');
		}
	});

    $body.on('keyup', '.phrase-add', function (e) {
        if (e.which === 13 || e.which === 188) {
			e.preventDefault();
            console.log(e.which);
            var $add = $(this),
                $form = $add.closest('form'),
				fill = $add.closest('.finder-data').data('fill'),
                $fill = getObject(fill, $add),
                action = $fill.data('action') + '/1',
                $tags = $add.closest('.finder-fields').find('.tags-area'),
                $messages = $add.closest('.finder-fields').find('.msg-area'),
                nr = $tags.find('input').length + 1,
				search = $.trim($add.val().replace(',',''));
//			console.log('fill', fill);
//			console.log('$fill', $fill);
			console.log('finder action', action);

            if (search.length > 2) {
                $tags.append('<div class="tag">' +
                    '<div class="card">' +
                    '<div class="card-body">' +
                    '<input readonly type="text" class="form-control-plaintext" name="phrase[' + nr + ']" value="' + search + '">' +
                    '<i class="fas fa-times clear-tag"></i>' +
                    '</div>' +
                    '</div>');
                $add.val('');
                $messages.html('');
				var data = dataFromForm($form, false);
                submitData(data, action, $fill);
                return false; // żeby Enter nie wysłał formularza
            } else {
                $messages.html('<span class="msg mb-3">Wpisz minimum 3 znaki!</span><i class="fas fa-times clear-msg"></i>');
            }
        }
    });

    // usunięcie tagu wyszukiwarki
    $body.on('click', '.clear-tag', function () {
        var $tag = $(this).closest('.tag'),
            $form = $tag.closest('form'),
			fill = $tag.closest('.finder-data').data('fill'),
			$fill = getObject(fill, $tag),
            action = $fill.data('action');
        $tag.toggle('hide');
        setTimeout(function(){
            $tag.remove();
            submitData(dataFromForm($form, false), action, $fill);
        },200);

    });

    // usunięcie komunikatu wyszukiwarki
    $body.on('click', '.clear-msg', function () {
        $(this).closest('.msg-area').html('');
    });

	/////// WYSZUKIWARKA KONIEC //////

	// aktywacja tooltips (dymków)
	// $('[data-toggle="tooltip"]').tooltip();
	// $('[data-toggle*="tooltip"]').tooltip();

	// aktywacja edytora html
	runEditor();

	$body.find('.sf-dump').css('z-index', 1);

	//Nawigacja Administracyjna
	$body.on('load', '.auto-display', function () {});

});

function setDisplay($btn, toHide, toShow){
	console.log('Przełącznik pól', 'ukryć: ' + toHide + ', pokazać: ' + toShow);
	console.log('array hide?', $.isArray(toHide));
	console.log('array show?', $.isArray(toShow));
	if(toHide !== undefined){
		if($.isArray(toHide)) {
			$.each(toHide, function(i, el){
				var $toHide = getObject(el, $btn);
				console.log('hide' + i, $toHide);
				setObjectClass($toHide, 'hide');
			});
		}else{
			var $toHide = getObject(toHide, $btn);
			console.log('hide',$toHide);
			setObjectClass($toHide, 'hide');
		}
	}
	if(toShow !== undefined){
		if($.isArray(toShow)){
			$.each(toShow, function(i, el){
				var $toShow = getObject(el, $btn);
				console.log('show ' + i, $toShow);
				setObjectClass($toShow, 'show');
			});
		}else{
			var $toShow = getObject(toShow, $btn);
			console.log('show',$toShow);
			setObjectClass($toShow, 'show');
		}
	}
}

function buttonAction($btn){
	var allData = $btn.data();
	console.log('buttonAction/$btn.data', allData);
	if(allData === undefined || allData.actionType === undefined){
		console.log('Error click ".' + $btn.prop('class') + '"', 'Brak zdefiniowanego data-action-type!');
	}
	switch(allData.actionType){
		case 'submit': // html-owy submit formularza; btn musi mieć data-form, a form - action
			sendSubmit(allData, $btn); //in controller: $query = $request->request->all();
			break;
		case 'post': // jQuery post
			sendPost(allData, $btn);
			// in controller: $query = json_decode($request->getContent(), true);
			// ale jeśli jest data-json= i nie jest "true" lub "1", to $query = $request->request->all();
			break;
		case 'get': // jQuery get
			sendGet(allData, $btn); //in controller: data from url
			break;
		case 'file': // Ajax z uploadem plików
			sendFile(allData, $btn);
			// in controller: $query = $request->request->all();
			// oraz  $file = $request->files->get('upload');
			break;
		case 'reset':
			resetForm(allData, $btn);
			break;
		case 'after': // bez send, tylko after, np. przycisk cancel;
			afterSend(allData, $btn);
			break;
		case 'link': // get klasyczny z przeładowaniem strony
			window.location.assign(allData.action);
			break;
		default:
			console.log('Error click ".' + $btn.prop('class') + '"', 'Zdefiniowany typ akcji "' + allData.actionType + '" nie posiada obsługi programowej!');
	}
	$btn.blur();
}

function resetForm(d, $btn){
	console.log('resetForm', d);
	var sendOK = true;
	if(d.confirm !== undefined){
		sendOK = confirm(d.confirm);
	}
	var $form = getObject(d.form, $btn);
	console.log('form', $form);
	if($form && sendOK){
		var $inputs = $form.find('input');
		console.log('inputs',$inputs);
		if($inputs !== undefined){
			$inputs.each(function(){
				var $o = $(this),
					$t = $o.prop('type');
				if( $t === 'text' || $t === 'number' || $t === 'email' || $t === 'radio' || $t === 'checkbox'){
					$o.val('').prop('checked', false).prop('selected', false);
				}
			});
		}
		var $textarea = $form.find('textarea');
		if($textarea !== undefined){
			$textarea.each(function(){
				$(this).val('');
			});
		}
	}
}

function sendSubmit(d, $btn){
    let sendOK = true;
	if(d.confirm !== undefined){
		sendOK = confirm(d.confirm);
	}

	let $form = getObject(d.data, $btn);
	console.log('sendSubmit $form',$form);
	if($form !== undefined && beforeSend($form) && sendOK){
		$form.submit();
	}
}

function sendPost(d, $btn){
	console.log('sendPost', d);
	var sendOK = true;
	if(d.confirm !== undefined){
		sendOK = confirm(d.confirm);
	}
	if(sendOK){
		if(d.data === undefined){
			if(d.form !== undefined) {
				var $form = getObject(d.form, $btn);
			}else{
				var $form = $btn.closest('form');
			}
		}else{
			var $form = getObject(d.data, $btn);
		}
	}
	if($form !== undefined){
		if(d.action === undefined){
			var action = $form.prop('action') !== undefined ? $form.prop('action') : undefined;
		}else{
			var action = d.action;
		}
		if(action !== undefined){
			var $json = d.json === undefined ? false : (d.json === 'true' || d.json === '1' ? true : false);
			var bs = beforeSend($form);
			console.log('beforeSend',bs);
			var cu = checkUpload($form);
			console.log('checkUpload',cu);
			if(bs && cu){
				submitData(
					dataFromForm($form, $json),
					action,
					getObject(d.fill, $btn),
					d.typeFill !== undefined ? d.typeFill : 'html',
					getObject(d.formFind, $btn), // dla formularza wyszukiwarki
					getObject(d.fillFind, $btn), // fill akcji po wyszukiwaniu
					getObject(d.beforeMessage, $btn)
				);
				console.log('INFO .admin-action.btn', 'Wysłano.');
				afterSend(d, $btn);
				console.log('INFO .admin-action.btn', 'wykonano afterSend, o ile było.');
			}else{
				console.log('ERROR .admin-action.btn', 'Bład akcji wstępnej lub nie przeszedł test wypełńiania formularza!');
			}
		}else{
			console.log('ERROR .admin-action.btn', 'Nie określono akcji!');
		}
	}else{
		console.log('ERROR .admin-action.btn', 'Nie określono umiejscowienia danych - formularza!');
		return false;
	}
}

function sendGet(d, $btn){
	console.log('sendGet', d, $btn);
	var sendOK = true;
	if(d.confirm !== undefined){
		sendOK = confirm(d.confirm);
	}
	if(d.action !== undefined && sendOK){
		getData(
			d.action,
			getObject(d.fill, $btn),
			d.typeFill !== undefined ? d.typeFill : 'html',
			getObject(d.formFind, $btn),
			getObject(d.fillFind, $btn),
			getObject(d.beforeMessage, $btn)
		);
		afterSend(d, $btn);
	}else if(sendOK){
		console.log('ERROR .admin-action.btn', 'Nie ustawiono akcji!');
	}
}

function sendFile(d, $btn){
	console.log('sendFile data', d, 'sendfile button', $btn);
	var sendOK = true;
	if(d.confirm !== undefined){
		sendOK = confirm(d.confirm);
	}
	if(sendOK){
		if(d.data === undefined){
			if(d.form !== undefined) {
				var $form = getObject(d.form, $btn);
			}else{
				var $form = $btn.closest('form');
			}
		}else{
			var $form = getObject(d.data, $btn);
		}
		if($form !== undefined){

			if(d.action === undefined){
				var action = $form.prop('action') !== undefined ? $form.prop('action') : undefined;
			}else{
				var action = d.action;
			}
//			console.log('sendFile action', action);
//			return false;

			if(action != undefined && action.length){
				if(beforeSend($form) && checkUpload($form)){
					submitDataFile(
						$form[0],
						action,
						getObject(d.fill, $btn),
						d.typeFill !== undefined ? d.typeFill : 'html',
						getObject(d.beforeMessage, $btn)
					);
					afterSend(d, $btn);
				}else{
					console.log('ERROR .admin-action.btn', 'Formularz nie ma pola "upload" lub plik jest za duzy lub nie ma wszystkich wymaganych danych!');
					return false;
				}
			}else{
				console.log('ERROR .admin-action.btn', 'Nie określono akcji dla formularza');
				return false;
			}
		}else{
			console.log('ERROR .admin-action.btn', 'Nie określono umiejscowienia danych - formularza!');
			return false;
		}
	}else{
		console.log('CANCEL .admin-action.btn', 'Nie potwierdzono wykonania akcji!');
		return false;
	}
}

function beforeSend($form){
	// sprawdzenie wymaganych inputów i checkboxów, czy mają wartość
	var goodData = true;
	$form.find('input').each(function () {
		var $inp = $(this);
		if ($inp.hasClass('error')) {
				$inp.removeClass('error');
				$inp.siblings('.alert').remove();
		}

		if ($inp.hasClass('custom-file-input') && $inp.parent().find('.custom-file-label').hasClass('error')){
			$inp.parent().find('.custom-file-label').removeClass('error');
		}

		if($inp.hasClass('custom-file-input')) {
			if ($inp.hasClass('error-type')) {
				beforeAlert($inp, 'Zły typ pliku, użyj png lub jpg!');
				goodData = false;
				console.log('.custom-file-input.error-type', 'Zły typ pliku, użyj png lub jpg!');
			} else if ($inp.hasClass('error-file')) {
				beforeAlert($inp, 'Dłuższy bok nie może przekraczać 2400 pikseli!');
				goodData = false;
				console.log('.custom-file-input.error-file', 'Dłuższy bok nie może przekraczać 2400 pikseli!');
			}
		}

		if ($inp.is(':visible') && $inp.prop('required') && (
				($inp.prop('type') === 'checkbox' && !$inp.is(':checked')) ||
				($inp.prop('type') !== 'checkbox' && !$inp.val().trim().length)
				)) {
			if($inp.hasClass('custom-file-input')){
				$inp.parent().find('.custom-file-label').addClass('error');
			}else{
				$inp.addClass('error');
			}
			console.log('inp jest wymagane', 'start test');
			beforeAlert($inp, 'Pole "' + $inp.prop('name') + '" jest wymagane!');
			console.log('inp jest wymagane', 'stop test');
			goodData = false;
		} else if ($inp.hasClass('error')) {
			if($inp.hasClass('custom-file-input')){
				$inp.parent().find('.custom-file-label').removeClass('error');
			}else{
				$inp.removeClass('error');
			}
		}
	});
	// sprawdzenie wymaganych selectów
	$form.find('select').each(function () {
		var $sel = $(this);
		if ($sel.hasClass('error')) {
			$sel.removeClass('error');
			$sel.siblings('.alert').remove();
		}
		if($sel.is(':visible') && $sel.prop('required') && (!parseInt($sel.val(), 10) || !$sel.val().length)) {
			console.log('select.each required ', 'start test');
			$sel.addClass('error');
			beforeAlert($sel, 'Wybierz...');
			goodData = false;
			console.log('select.each required ', 'Wybierz...');
		}
	});
	// jeśli nie wszystkie wymagane pola są wypełnione, kończymy
	if (!goodData) {
		console.log('end test - beforeAlert', 'Uzupełnij dane');
		beforeAlert($form, 'Uzupełnij dane!');
	}
	return goodData;
}

function afterSend(d, $btn = undefined){
	setTimeout(function(){

		if(d.hide !== undefined) {
			setObjectClass(getObject(d.hide, $btn), 'hide');
		}
		if(d.show !== undefined) {
			setObjectClass(getObject(d.show, $btn), 'show');
		}
		if(d.inactive !== undefined) {
			setObjectClass(getObject(d.inactive, $btn), 'inactive');
		}
		if(d.active !== undefined) {
			setObjectClass(getObject(d.active, $btn), 'active');
		}
		if(d.after !== undefined){
			var a = d.after;
			if($.isArray(a)){
				for (let i = 0; i < a.length; ++i) {
					afterAction(a[i], $btn);
				}
			}else{
				afterAction(a, $btn);
			}
		}
	}, 1000);
	// collapse wykonać szybko
	if(d.after !== undefined && d.after.collapse !== undefined){
		setTimeout(function(){
			var $toHide = getObject(d.after.collapse, $btn);
			$toHide.collapse('hide');
		}, parseInt(d.after.collapse.time, 10));
	}
}

function afterAction(a, $btn = undefined){
	console.log('aferAction', a);
	if(a.get !== undefined){
		getData(a.get.action, getObject(a.get.fill, $btn));
	}
	if(a.runEditor !== undefined){
		runEditor(a.runEditor, 200, $btn);
	}
	if(a.scrool !== undefined){
		var $obj = $btn.closest(a.scrool.parent),
			$to = $obj.find(a.scrool.to);
		console.log('scrool', $obj, ' to ', $to);
		if($obj !== undefined && $to !== undefined && $obj.length && $to.length){
			$obj.animate({ scrollTop: $to.offset().top }, 200);
		}
	}
	if(a.hide !== undefined) {
		var $obj = getObject(a.hide, $btn);
		console.log('to hide', $obj);
		if($obj !== undefined){
			if($obj.hasClass('modal')){
				$obj.modal('hide');
			}else{
				$obj.siblings('.active').removeClass('active');
				$obj.addClass('d-none');
			}
		}
	}
	if(a.collapse !== undefined) {
		let $objCollapse = getObject(a.collapse, $btn);
		if($objCollapse !== undefined && $objCollapse.lenght && $objCollapse.hasClass('collapse')) {
			setTimeout(function(){
				$objCollapse.collapse();
			}, parseInt(a.collapse.time, 10));
		}
	}
	if(a.navTools !== undefined) {
		// podawanie w anywhere na sztywno jakiegoś id z jakiejś podstrony, to nie jest dobry pomysł
		let navToolsBar = document.querySelector('#navToolsBar');
		if($(navToolsBar) !== undefined && $(navToolsBar).lenght && !$(navToolsBar).hasClass('push-1x')) {
			$(navToolsBar).addClass('push-1x');
		}
	}
}

function resetIframe($iframe){
	if($iframe !== undefined && $iframe.length){
		var src = $iframe.prop('src');
		$iframe.prop("src", '').prop("src", src);
	}
}
function resetVideo($video){
	if($video !== undefined && $video.length){
		$video.each(function(){this.pause(); this.currentTime=0;});
	}
}

function removeElement($obj){
	var $inputArea = $obj.closest('.input-group'),
		$add = $inputArea.find('input');
	console.log('clear', $add.prop('class'));
	$inputArea.addClass('scale-out-center');
	$add.val('');
	$add.focus();
}

function createWidthCookie() {
	var width = $("body > .width").map(function() {
		if($(this).is(":visible")){
			return $(this).data('width');
		}
	}).get();
	$('body').data('width', width[0]);
	createCookie('bootstrap_width', width[0], 30);
}

function resetModalTitles(form, mediaTypes) {
	for(const type of mediaTypes) {
		$(form).find('.modal-title-' + type).addClass('d-none');
	}
}
