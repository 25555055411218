export {plusSlides, currentSlide, showSlides}

///// modal lightbox ///////
// Open the Modal
var slideIndex = 1;
// Next/previous controls
function plusSlides(n, $modal) {
	showSlides(slideIndex += n, $modal);
}
// Thumbnail image controls
function currentSlide(n, $modal) {
	showSlides(slideIndex = n, $modal);
}
// Good image show
function showSlides(n, $modal) {
	var i;
	var slides = $modal.find(".slide-image"),
		names = $modal.find(".slide-name"),
		dots = $modal.find(".slide-thumb");
	console.log('show Slide Index ' + n + '/' + slides.length, '- ID mmodala galerii: #' + $modal.prop('id'));
	if (n > slides.length) {
		slideIndex = 1;
		console.log('slideIndex if ' + n + ' > ' + slides.length, slideIndex);
	} else if (n < 1) {
		slideIndex = slides.length;
		console.log('slideIndex if ' + n + ' < ' + 1, slideIndex);
	} else {
		slideIndex = n;
		console.log('slideIndex if ' + n + ' >= 1 and <= ' + slides.length, slideIndex);
	}
	for (i = 0; i < slides.length; i++) {
		slides[i].style.display = "none";
	}
	for (i = 0; i < names.length; i++) {
		names[i].style.display = "none";
	}
	for (i = 0; i < dots.length; i++) {
		if(slides.length > 1){
			dots[i].className = dots[i].className.replace(" active", "");
		}else{
			dots[i].style.display = "none";
		}
	}
	var idx = slideIndex - 1;
	slides[idx].style.display = "block";
	names[idx].style.display = "block";
	if(slides.length > 1){
		dots[idx].className += " active";
	}
}
/////// modal lightbox end ///////



